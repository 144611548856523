import React from 'react';
import YouTube from 'react-youtube';
import '../App.css';

class YouTubePlay extends React.Component {

    render() {
        const videoId="T1LYxpu2KS0";
        const opts = {
            width: '100%',
            heigth: '100%',
            playerVars: {
                autoplay: 1,
                loop: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0
            }
        };

        return  <div>
                    <div className="center">3 minutes at best.</div>
                    <YouTube opts={opts} containerClassName={"youtubeContainer"} videoId={videoId} />
                </div>;

    }
}

export default YouTubePlay;